import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SubSink} from 'subsink';
import {Api} from '../api/api';

@Injectable({
    providedIn: 'root',
})
export class ConfigurationService implements OnDestroy {
    private subs = new SubSink();

    constructor(private httpClient: HttpClient,
                private api: Api) {
    }
    async getShowTopReadersModal(): Promise<boolean> {
        try {
            const response: any = await this.api.get('/configuration/showTopReadersModal', null);
            return response.result;
        } catch (e) {
            return null;
        }
    }

    async getPaymentService(): Promise<string> {
        try {
            const response: any = await this.api.get('/configuration/payment-service', null);
            return response.result;
        } catch (e) {
            return null;
        }
    }




    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
