import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {NGXLogger, NgxLoggerLevel} from 'ngx-logger';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {TabDirective, TabsetComponent} from 'ngx-bootstrap/tabs';
import {AuthService} from '../../../../services/auth.service';
import {AccountService} from '../../../consultant/services/account.service';
import {ReaderService} from '../../services/reader-service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ServerErrors} from '../../helpers/server-errors';
import {FormHelper} from '../../helpers/form.helper';
import {ConfirmPaymentModalComponent} from '../confirm-payment-modal/confirm-payment-modal.component';
import {finalize} from 'rxjs/operators';
import {PaymentService} from '../../../customer/services/payment.service';
import {AlertService} from '../../../../services/alert.service';
import {UserService} from '../../../../services/user.service';
import {CongratualationsModalComponent} from '../modals/congratualations-modal/congratualations-modal.component';
import {Router} from '@angular/router';
import {AllEmiterService} from '../../../../services/all-emiter.service';
import {SubSink} from 'subsink';
import {ResizeService} from '../../../../services/resize.service';
import {SCREEN_SIZE} from '../../../../screen-size.enum';
import {GoogleAnalyticsEventsService} from '../../../../services/google-analytics-events.service';
import {UtilService} from '../../../../services/util.service';
import {WINDOW} from '../../../../providers/window.provider';
import {CcAttemptsService} from '../../../../services/nestjs/logs/cc-attempts/cc-attempts.service';
import {loadScript} from '@paypal/paypal-js';
import {CCState} from '../../../../services/nestjs/logs/cc-attempts/enum/cc-state.enum';
import {PaypalNestService} from '../../../../services/nestjs/paypal/paypal-nest.service';
import {UserCustomerService} from '../../../../services/nestjs/user-customer/user-customer.service';
import {SignFormToAddCcResponseDto} from '../../../../services/nestjs/user-customer/dto/sign-form-to-add-cc.response.dto';
import {ConfigurationService} from '../../../../services/nestjs/configuration/configuration.service';
import * as Sentry from '@sentry/angular';
@Component({
    selector   : 'app-registration-step3',
    templateUrl: './registration-step3.component.html',
    styleUrls  : ['./registration-step3.component.scss'],
})
export class RegistrationStep3Component extends ServerErrors implements OnInit, OnChanges, AfterViewInit, OnDestroy {
    private subs = new SubSink();
    doSignIn: EventEmitter<boolean> = new EventEmitter();
    // handler: any;
    // stripeEnabled = false;

    promotionList;

    ccForm: FormGroup;

    loading = false;
    promotions: any = [];
    promotionsCC: any = [];
    public onData = new EventEmitter();

    public payPalConfig ?: any;
    private staticTabs: TabsetComponent;

    activeTab = 0;

    private addfundsFirstTimepaypal = false;

    currency = null;

    currentStep = 0;
    paygWelcome = false;

    size: SCREEN_SIZE;

    private interval;
    private paypal;
    public paypalData = {
        orderID: '',
        payerID: '',
        facilitatorAccessToken: '',
        error: ''
    };
    sendingData = false;
    @ViewChild('paygRadio') paygRadio: ElementRef;
    paygValue: false;

    paymentServiceValue = '';

    public cybersourceData: SignFormToAddCcResponseDto = null;
    constructor(
        private authService: AuthService,
        private logger: NGXLogger,
        private bsModalService: BsModalService,
        private fb: FormBuilder,
        public bsModalRef: BsModalRef,
        private paymentService: PaymentService,
        private alertService: AlertService,
        public userService: UserService,
        private router: Router,
        private allEmiterService: AllEmiterService,
        private resizeSvc: ResizeService,
        private utilService: UtilService,
        private gAE: GoogleAnalyticsEventsService,
        @Inject(WINDOW) private window: Window,
        private logService: CcAttemptsService,
        private paypalNestService: PaypalNestService,
        private userCustomerService: UserCustomerService,
        private configurationService: ConfigurationService,
    ) {
        super();
        this.buildForm();
        this.buildCCForm();
        this.subs.sink = this.resizeSvc.onResize$.subscribe(x => {
            this.size = x;
        });
    }

    async ngOnInit() {
        const self = this;
        this.currentStep = this.userService.getStep();
        Sentry.addBreadcrumb({
            category: 'Registration step 3',
            data: {currentStep: this.currentStep},
            level: Sentry.Severity.Debug,
        });
        this.getCurrency();
        if (this.currentStep === 3 ) {
            this.paymentServiceValue = await this.configurationService.getPaymentService();
            Sentry.addBreadcrumb({
                category: 'Registration step 3',
                data: {paymentServiceValue: this.paymentServiceValue},
                level: Sentry.Severity.Debug,
            });
            if (this.paymentServiceValue === 'cybersource') {
                await this.generateSignature();
            }

        } else if (this.currentStep === 4) {
            this.creditCardsPromotions(0);
            this.paypalPromotions(0);
            // To be able to show the welcome message after user add funds using paypal for the first time I have do add a listener
            if (this.allEmiterService.subsAddFundsFirstTime === undefined) {
                this.subs.sink = this.allEmiterService.subsAddFundsFirstTime =
                    this.allEmiterService.invokeAddFundsFirstTime.subscribe(async (data) => {
                        if (this.addfundsFirstTimepaypal) {
                            await this.gAE.fireFirstPaypalEvent(null, self.utilService.localStorageGetItem('uid'));
                            this.afterFundsWereAddedFirstTimePaypal();
                        }
                    });
            }
            this.paygWelcome = this.userService.getPaygWelcome();
            // Sent logs to check that welcome offers were shown
            this.validateWelcomeOffersWereShown();
        }


        // To change the currency
        if (this.allEmiterService.subsFundsChange === undefined) {
            this.subs.sink = this.allEmiterService.subsFundsChange =
                this.allEmiterService.invokeFundsChange.subscribe((data) => {
                    this.getCurrency();
                });
        }




    }

    creditCardsPromotions(counter) {
        const self = this;
        if (this.promotionsCC.size === 0) {
            if ( counter <= 1) {
                this.subs.sink = this.paymentService.getPromotionsRegistrationCC().subscribe(async (res2) => {
                    if (res2 === null) {
                        // This means there was an issue with the currencies, so I go the user information again
                        await this.userService.getDetails().toPromise();
                        this.creditCardsPromotions(counter++);
                    } else {
                        self.utilService.setTimeout(async () => {
                            this.promotionsCC = res2;

                        }, 10);
                    }
                });
            } else {
                console.error('Error getting the cc promotions');
            }
        }

    }

    paypalPromotions(counter) {
        const self = this;
        if (counter <= 1) {
            this.subs.sink = this.paymentService.getPromotionsRegistrationPaypal().subscribe(async (res2) => {
                if (res2 === null) {
                    // This means there was an issue with the currencies, so I go the user information again
                    await self.userService.getDetails().toPromise();
                    self.paypalPromotions(counter + 1);
                } else {
                    self.promotions = res2;
                    await self.loadPaypal();
                }
            });
        } else {
            console.error('Error getting the paypal promotions');
        }

    }

    buildForm() {
        this.form = this.fb.group({
            billingAddress: [''],
            zip           : [''],
        });
    }


    async doSubmit() {
        this.sendingData = true;
        // Generate random text to identify this attempt
        const ccAttempt = Math.random().toString(36).slice(2, 7);
        this.utilService.localStorageSetItem('ccAttempt', ccAttempt);
        this.utilService.localStorageSetItem('creditCard', 'add');
        // this.logger.log('Adding credit card', LogEvents.CreditCard, ccAttempt);
        this.subs.sink = this.logService.createLogCC('Adding credit card', NgxLoggerLevel.LOG).subscribe();
        const self = this;
        FormHelper.touchedForm(this.form);
        if (!this.form.valid) {
            console.log('form not valid');
            this.subs.sink = this.logService.addEventCC('Form not valid, so credit card is not updated', NgxLoggerLevel.ERROR).subscribe();
            this.sendingData = false;
            return;
        }
        // this.allEmiterService.onLoadingChange(true);
        console.log('before try');
        try {
            const res = await this.authService.registrationReaderStep3(this.form.value).toPromise();
            self.utilService.localStorageRemoveItem('registration');
            // this.logger.log('registrationReaderStep3 answer', LogEvents.CreditCard, ccAttempt, res.url);
            self.subs.sink = self.logService.addEventCC('registrationReaderStep3 answer ' + res.url, NgxLoggerLevel.LOG).subscribe();
            self.subs.sink = self.logService.addEventCC('Redirecting user ' + res.url, NgxLoggerLevel.LOG).subscribe();
            self.window.location.replace(res.url);
        } catch (e) {
            // this.allEmiterService.onLoadingChange(false);
            this.logger.error('Error on updateCreditCard ws', e);
            this.subs.sink =
                this.logService.addEventCC('Error on registrationReaderStep3 ws: ' + e.toString(), NgxLoggerLevel.ERROR).subscribe();
            this.subs.sink = this.logService.resultCC(CCState.ERROR).subscribe();
        }
    }

    private initConfig(): void {
        // const self = this;
        // const conf = this.paymentService.getPaypalConfig(
        //     function createOrderOnServer(data) {
        //         return self.paymentService.payPalCreateOrderToPromise(self.ccForm.controls['promotionCC'].value.id).then(
        //             function (paymentId) {
        //                 return paymentId;
        //             }
        //         );
        //     },
        //     function authorizeOnServer(data, actions) {
        //         self.subs.sink = self.paymentService.payPalAuth(data).subscribe(async (res) => {
        //             await self.userService.getDetailsToPromise();
        //             await self.gAE.completeRegistration();
        //             self.allEmiterService.onFundChange(0);
        //             self.bsModalRef.hide();
        //             self.window.location.reload();
        //         }, (err) => {
        //             const paypalError = err.error;
        //             console.error(paypalError);
        //             throw err;
        //         });
        //     },
        //     function onCancel(data, actions) {
        //         self.logger.debug('OnCancel', data, actions);
        //     },
        //     function onError(err) {
        //         self.logger.debug('onError', err);
        //     },
        //     function onClick(data, actions) {
        //
        //     },
        //     function onInit(data, actions) {
        //         actions.disable();
        //         self.ccForm.statusChanges.subscribe(
        //             result => {
        //                 if (result === 'VALID') {
        //                     actions.enable();
        //                 } else {
        //                     actions.disable();
        //                 }
        //             }
        //         );
        //     }
        // );
        // conf.style = {
        //     label: 'checkout',
        //     shape:   'rect',
        //     size: 'medium',
        //     tagline: true,
        //     layout: 'horizontal'
        // };
        // this.payPalConfig = conf;
        //
        // this.payPalScriptService.registerPayPalScript({
        //     clientId: conf.clientId,
        //     currency: conf.currency
        // }, (payPalApi) => {
        //     if (self.payPalElemRegistration) {
        //         self.payPalElemRegistration.customInit(payPalApi);
        //     } else {
        //         self.interval = self.utilService.setInterval(function () {
        //             if (self.payPalElemRegistration) {
        //                 self.payPalElemRegistration.customInit(payPalApi);
        //                 clearInterval(self.interval);
        //             }
        //         }, 1000);
        //     }
        // });
    }

    async loadPaypal() {
        const self = this;
        try {
            this.paypal = await loadScript(this.paymentService.getPaypalConfiguration());
        } catch (error) {
            this.logger.error('failed to load the PayPal JS SDK script', error);
        }

        if (this.paypal) {
            try {
                // noinspection TypeScriptValidateJSTypes
                await this.paypal.Buttons({
                    style: {
                        label: 'checkout',
                        shape:   'rect',
                        size: 'medium',
                        tagline: true,
                        layout: 'horizontal'
                    },
                    createOrder: async () => {
                        self.logger.debug('createOrderOnServer');
                        // return self.paymentService.payPalCreateOrderToPromise(self.ccForm.controls['promotionCC'].value.id);
                        const createOrder = await self.paypalNestService.createOrder(this.ccForm.controls['promotionCC'].value.id);
                        return createOrder.paymentID;
                    },
                    onApprove: async (data, actions) => {
                        self.logger.debug('authorizeOnServer', data);
                        self.paypalData.payerID = data.payerID;
                        self.paypalData.orderID = data.orderID;
                        self.paypalData.facilitatorAccessToken = data.facilitatorAccessToken;
                        try {
                            // const result = await self.paymentService.payPalAuth(self.paypalData).toPromise();
                            const captureOrderDto =
                                await self.paypalNestService.captureOrder(
                                    data.orderID,
                                    data.payerID,
                                    data.facilitatorAccessToken);
                            await self.userService.getDetailsToPromise();
                            await self.gAE.completeRegistration();
                            self.allEmiterService.onFundChange(0);
                            self.bsModalRef.hide();
                            self.window.location.reload();
                        } catch (e) {
                            self.logger.error(e);
                            self.alertService.alert({
                                title: 'PayPal',
                                body: 'Please check your balance and try again OR try a Credit Card to add funds.',
                                type: 'error',
                            });
                        }
                    },
                    onCancel: (data, actions) => {
                        self.logger.debug('OnCancel', data, actions);

                    },
                    onClick: function () {
                    },
                    onError: function (err)  {
                        self.logger.debug('onError', err);
                    },
                    // onInit is called when the button first renders
                    onInit: function(data, actions) {
                        actions.disable();
                        self.ccForm.statusChanges.subscribe(
                            result => {
                                if (result === 'VALID') {
                                    actions.enable();
                                } else {
                                    actions.disable();
                                }
                            }
                        );
                    }
                }).render('#paypalRegistration');
            } catch (error) {
                self.logger.error('failed to render the PayPal Buttons', error);
            }
        }
    }

    private afterFundsWereAddedFirstTimePaypal() {
        this.bsModalRef.hide();
        this.bsModalService.show(CongratualationsModalComponent, {
            backdrop: true,
            keyboard: true,
            animated: false,

        });
    }

    addFundsCC() {
        FormHelper.touchedForm(this.ccForm);
        if (!this.ccForm.valid) {
            return;
        }
        const userData = this.userService.getData();
        if (userData.blocked === 'UNBLOCKED') {
            const promotionCC  = this.ccForm.controls['promotionCC'].value;
            if (promotionCC.id > -1) {
                const initialState = {
                    data: {
                        amountAdded: promotionCC.info.amount + promotionCC.info.bonus,
                        amount: promotionCC.info.amount,
                        asciiSymbol: promotionCC.info.currency.asciiSymbol,
                        confirmation: true,
                        payg: false
                    },
                };

                const confirm = this.bsModalService.show(ConfirmPaymentModalComponent, {
                    initialState: initialState,
                    class: 'modal-sm',
                    backdrop: 'static',
                    animated: false,
                });
                this.subs.sink = confirm.content.onConfirm.subscribe(() => {
                    this.loading = true;
                    this.subs.sink = this.paymentService.addPayment(promotionCC.id, {})
                        .pipe(finalize(() => {
                            this.loading = false;
                            // this.allEmiterService.onFundChange(initialState.data.amountAdded);
                        }))
                        .subscribe(async (res) => {
                            this.bsModalRef.hide();
                            this.alertService.alert({
                                title: `${initialState.data.asciiSymbol} ${initialState.data.amountAdded} was added to your account`,
                                body : '',
                                type : 'info',
                            });
                            this.bsModalService.show(CongratualationsModalComponent, {
                                backdrop: true,
                                keyboard: true,
                                animated: false,
                            });
                            await this.userService.getDetailsToPromise();
                        }, (err) => {
                            this.bsModalRef.hide();
                            if (err.error.code === '500') {
                                this.alertService.alert({
                                    title: '',
                                    body: err.error.message,
                                    type: 'error',
                                });
                                this.utilService.localStorageRemoveItem('addFundsAttempt');
                                this.router.navigate(['customer/credit-card']);
                            }
                            if (err.error.code === '400002' || err.error.message === 'Your attempt to top up your account was declined by your funding source') {
                                this.router.navigate(['customer/credit-card']);
                                this.alertService.alert({
                                    title: err.error.message,
                                    body: 'Please check your card balance and try again OR use PayPal to add funds.',
                                    type: 'error',
                                });
                            }
                            if (err.error.code === '400001') {
                                this.alertService.alert({
                                    title: 'Please update your credit card details' ,
                                    body: err.error.message,
                                    type: 'error',
                                });
                                this.router.navigate(['customer/credit-card']);
                            }
                            if (err.error.code === '400108') {
                                this.alertService.alert({
                                    title: 'Add funds' ,
                                    body: err.error.message,
                                    type: 'error',
                                });
                            }
                        });
                });
            } else {

                this.subs.sink = this.paymentService.setAutoRefill({auto: true, timeInterval: 10})
                    .pipe(finalize(() => {
                        this.loading = false;
                    }))
                    .subscribe(
                        (res) => {
                            try {
                                this.subs.sink = this.userService.getDetails().subscribe(() => {
                                    this.bsModalRef.hide();
                                    this.bsModalService.show(CongratualationsModalComponent, {
                                        backdrop: true,
                                        keyboard: true,
                                        animated: false,
                                    });
                                }, (e) => {
                                    if (e.message !== 'No user data') {
                                        throw e;
                                    }
                                });
                            } catch (e) {
                                console.error(e);
                            }
                        },
                        async (err) => {
                            this.bsModalRef.hide();
                            this.alertService.alert({
                                title: err.error.message,
                                body : '',
                                type : 'error',
                            });
                            if (err.error.code === 400002 || err.error.code === 400002) {
                                if (await this.userService.checkRegistration() !== true) {
                                    return;
                                }
                                this.router.navigate(['customer/credit-card']);
                            }
                        }
                    );
            }
        } else {
            this.alertService.alert({
                title: ``,
                body: 'We are sorry but adding funds is not possible at this time. Please contact Support.',
                type: 'info',
            });
        }



    }

    paygSelected(evt, modalBody) {
        const self = this;
        const target = evt.target;
        if (target.checked) {
            modalBody.style.opacity = 0.5;
            const initialState = {
                data: {
                    amount     : 10,
                    asciiSymbol: '',
                    payg: true,
                    amountAdded: 0,
                    confirmation: false,

                },
            };

            const confirm = this.bsModalService.show(ConfirmPaymentModalComponent, {
                initialState: initialState,
                backdrop    : 'static',
                animated: false,
            });
            this.subs.sink = confirm.content.onConfirm.subscribe(() => {
                modalBody.style.opacity = 1;
                self.addFundsCC();
                return true;
            });
            this.subs.sink = confirm.content.onCancel.subscribe(() => {
                modalBody.style.opacity = 1;
                this.ccForm.controls['promotionCC'].reset();
                return false;
            });
        } else {
            return true;
        }

    }

    buildCCForm() {
        this.ccForm = this.fb.group({
            promotionCC: ['', Validators.required],
        });
    }


    getCurrency() {
        const self = this;
        const aux = JSON.parse(self.utilService.localStorageGetItem('currency'));
        if (aux === null) {
            this.currency = {
                asciiSymbol: '$',
                id: 1,
                sign: 'USD'
            };
        } else {
            this.currency = aux;
        }
    }

    @ViewChild('staticTabs', {static: false}) set content(content: TabsetComponent) {
        const self = this;
        this.staticTabs = content;
        if (this.staticTabs && this.staticTabs.tabs) {
            self.utilService.setTimeout(() => {
                this.staticTabs.tabs[this.activeTab].active = true;
            });
        }
    }

    tabSelected(event: TabDirective, pos: number) {
        const self = this;
        if (pos !== this.currentStep) {
            this.ccForm.controls['promotionCC'].reset();
        }
        // Save local var to know which payment method was added. https://lifereader.atlassian.net/browse/LA-321
        self.utilService.localStorageSetItem('lastPaymentSystem', pos + '');
    }

    ngOnChanges(changes: SimpleChanges): void {

    }


    ngAfterViewInit(): void {
        try {
            // https://lifereader.atlassian.net/browse/LA-1189
            if (this.paygRadio && this.paygRadio.nativeElement) {
                this.paygRadio.nativeElement.checked = this.paygValue;
            }

        } catch (e) {
            this.logger.error(e.message);
        }
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
        if (this.interval) {
            clearInterval(this.interval);
        }

    }

    validateWelcomeOffersWereShown() {
        const showOffers = this.utilService.localStorageGetItem('showOffers');
        if (showOffers) {
            if (this.currentStep === 4) {
                this.subs.sink = this.logService.addEventCC(`Welcome Offers Shown` , NgxLoggerLevel.LOG).subscribe();
                this.subs.sink = this.logService.resultCC(CCState.OFFERS_SHOWN).subscribe();
                this.utilService.localStorageRemoveItem('showOffers');
            } else {
                this.subs.sink = this.logService
                    .addEventCC(`Welcome Offers No shown, step: ${this.currentStep}` , NgxLoggerLevel.LOG).subscribe();
                this.subs.sink = this.logService.resultCC(CCState.OFFERS_NO_SHOWN).subscribe();
                this.utilService.localStorageRemoveItem('showOffers');
            }
        }
    }

    async generateSignature() {
        try {
            this.cybersourceData = await this.userCustomerService.registrationStep3();
            Sentry.addBreadcrumb({
                category: 'Registration step 3',
                data: {cybersourceData: this.cybersourceData},
                level: Sentry.Severity.Debug,
            });
        } catch (e) {
            Sentry.captureException(e, {
                tags: {
                    'component': 'Registration step 3',
                },
            });
        }
    }

}
